import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fetchData } from "./Datafetcher";
import initialState from "./dataState";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [data, setData] = useState(initialState);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const result = await fetchData();
      setData(result);
    };

    fetchDataAsync();
  }, []);

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataProvider;
