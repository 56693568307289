import React, { useState } from "react";
import Rating from "../Rating";

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img
                src="https://randomuser.me/api/portraits/men/75.jpg"
                alt="Avatar"
              />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">AHMED ALI</h3>
                <p className="cs_heading_color mb-0">Dubai, UAE</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img
                src="https://imgs.search.brave.com/gvi_BkbCAlYgjhxYCe7pKb4RQ5hIm4gV3AxwMUG__b0/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pMC53/cC5jb20vd3d3LnVs/dHJhdXBkYXRlcy5j/b20vd3AtY29udGVu/dC91cGxvYWRzLzIw/MTgvMDQvb3JpZ2lu/YWwuanBnP3Jlc2l6/ZT01NTAsNTMz"
                alt="Avatar"
              />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">FATIMA HUSSAIN</h3>
                <p className="cs_heading_color mb-0">Riyadh, Saudi Arabia</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img
                src="https://imgs.search.brave.com/BRWgsOL3wDINpjN9iHfVm9fpV1McuSOrvPWDF4qkQLU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90My5m/dGNkbi5uZXQvanBn/LzA3LzU1LzU2Lzc4/LzM2MF9GXzc1NTU2/Nzg2Nl95WVZqamlV/cDRFWGRtWnp1QzZW/QnNsQmhkeVVma3p3/Si5qcGc"
                alt="Avatar"
              />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">OMAR AL-SALEH</h3>
                <p className="cs_heading_color mb-0">Amman, Jordan</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              I traveled from Dubai for treatment through HelalHealthcare, and
              it was a seamless experience. The doctors and staff were
              exceptional, and I’m very satisfied with the medical services I
              received.
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              I came from Riyadh for my surgery, and HelalHealthcare provided
              excellent care. The entire process, from booking to treatment, was
              well-managed, and I felt at ease throughout the experience.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              Traveling from Jordan for my medical treatment was made easy
              thanks to HelalHealthcare. The doctors were highly professional,
              and the facilities were top-notch. I would recommend their
              services to anyone.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
      </div>
    </div>
  );
}
