import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

export const fetchData = async () => {
  try {
    const [
      citiesSnapshot,
      statesSnapshot,
      hospitalsSnapshot,
      departmentsSnapshot,
      designationsSnapshot,
    ] = await Promise.all([
      getDocs(collection(db, "cities")),
      getDocs(collection(db, "states")),
      getDocs(collection(db, "hospitals")),
      getDocs(collection(db, "departments")),
      getDocs(collection(db, "designations")),
    ]);

    return {
      cities: citiesSnapshot.docs.map((doc) => doc.data().cityName),
      states: statesSnapshot.docs.map((doc) => doc.data().stateName),
      hospitals: hospitalsSnapshot.docs.map((doc) => doc.data().hospitalName),
      departments: departmentsSnapshot.docs.map(
        (doc) => doc.data().departmentName
      ),
      designations: designationsSnapshot.docs.map(
        (doc) => doc.data().designationName
      ),
      loading: false,
      error: null,
    };
  } catch (err) {
    return {
      cities: [],
      states: [],
      hospitals: [],
      departments: [],
      designations: [],
      loading: false,
      error: "Failed to load data",
    };
  }
};
