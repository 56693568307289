import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  Container,
  Typography,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import BreadcrumbStyle2 from "../Breadcrumb/BreadcrumbStyle2";
import Section from "../Section";
import AppointmentSectionStyle2 from "../Section/AppointmentSection/AppointmentSectionStyle2";
import BannerSectionStyle9 from "../Section/BannerSection/BannerSectionStyle9";
import { pageTitle } from "../../helpers/PageTitle";

const DoctorDetails = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDoctor = async () => {
      const docRef = doc(db, "doctors", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDoctor(docSnap.data());
        setLoading(false);
      } else {
        console.log("No such document!");
        setLoading(false);
      }
    };

    fetchDoctor();
  }, [id]);

  pageTitle("Doctor Details");

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (!doctor) {
    return (
      <Container>
        <Typography variant="h6" align="center">
          Doctor not found
        </Typography>
      </Container>
    );
  }

  // Function to capitalize each word in a string
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Function to render expertise and section content with a single bullet point
  const renderContentWithSingleBulletPoint = (content) => {
    return content.split("\n").map((item, index) => {
      // Normalize the bullet points: ensure only one bullet point per line
      const normalizedItem = item.trim().replace(/^•+\s*/, "• ");

      // If the line does not start with a bullet point, add one
      const lineWithBullet = normalizedItem.startsWith("•")
        ? normalizedItem
        : `• ${normalizedItem}`;

      return (
        <Typography
          variant="body2"
          paragraph
          key={index}
          sx={{
            wordWrap: "break-word", // Ensures long words or URLs wrap
            textIndent: "-0.5em", // Pulls the first line of text back slightly
            paddingLeft: "1.5em", // Indents all text to align with the bullet point
          }}
        >
          {lineWithBullet}
        </Typography>
      );
    });
  };

  return (
    <>
      {/* Breadcrumb */}
      <BreadcrumbStyle2 />

      {/* Doctor Details Section */}
      <Section bottomMd={190} bottomLg={150} bottomXl={110}>
        <Container
          sx={{
            padding: "2rem 4rem 2.6rem 4rem",
            borderRadius: "8px",
            marginBottom: 4,
            marginTop: 4,
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{
              backgroundColor: "#1976D2",
              padding: "2rem 2rem 4rem 2rem",
              borderRadius: "8px",
              marginBottom: 4,
            }}
          >
            {/* Left Column - Profile Image */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  border: "2px solid white",
                  borderRadius: "8px",
                  padding: 2,
                  backgroundColor: "white",
                }}
              >
                <img
                  src={doctor.image}
                  alt={doctor.doctorName}
                  style={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: doctor.image.includes("transparent")
                      ? "white"
                      : "none",
                  }}
                />
              </Box>
            </Grid>

            {/* Right Column - Doctor's Main Details */}
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "white",
                  marginBottom: "1rem",
                }}
              >
                Dr.{capitalizeWords(doctor.doctorName)}
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginTop: "8px", color: "white", marginBottom: "1rem" }}
              >
                {doctor.degree}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "8px",
                  color: "white",
                  textTransform: "uppercase",
                  marginBottom: "1rem",
                }}
              >
                {doctor.currentPosition}
              </Typography>

              <Typography
                variant="body1"
                sx={{ marginTop: "8px", color: "white", marginBottom: "1rem" }}
              >
                {capitalizeWords(doctor.hospital)}
              </Typography>
              <Typography
                variant="body1"
                sx={{ marginTop: "8px", color: "white", marginBottom: "1rem" }}
              >
                Experience: {doctor.experience}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  marginTop: 2,
                  backgroundColor: "white",
                  color: "#1c2536",
                  width: "fit-content",
                  padding: "10px 20px",
                }}
              >
                Book an Appointment
              </Button>
            </Grid>
          </Grid>

          {/* Area of Expertise */}
          {doctor.expertise && (
            <Grid
              container
              spacing={4}
              sx={{
                marginTop: 4,
                backgroundColor: "white",
                color: "black",
                borderRadius: "8px",
                padding: 2,
                border: "2px solid #1c2536",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    marginBottom: "1rem",
                  }}
                >
                  Area of Expertise
                </Typography>
                {renderContentWithSingleBulletPoint(doctor.expertise)}
              </Grid>
            </Grid>
          )}

          {/* Sections with Content */}
          <Grid
            container
            spacing={4}
            sx={{
              marginTop: 4,
              backgroundColor: "white",
              color: "black",
              borderRadius: "8px",
              padding: 2,
              border: "2px solid #1c2536",
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                paddingRight: { md: 2 },
                borderRight: "2px solid #1c2536",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {doctor.sections &&
                doctor.sections
                  .filter((_, index) => index % 2 === 0)
                  .map((section, index) => (
                    <Box key={index} sx={{ marginBottom: 4 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        {capitalizeWords(section.sectionHeading)}
                      </Typography>
                      {renderContentWithSingleBulletPoint(
                        section.sectionContent
                      )}
                    </Box>
                  ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                paddingLeft: { md: 2 },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {doctor.sections &&
                doctor.sections
                  .filter((_, index) => index % 2 !== 0)
                  .map((section, index) => (
                    <Box key={index} sx={{ marginBottom: 4 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginBottom: "1rem",
                        }}
                      >
                        {capitalizeWords(section.sectionHeading)}
                      </Typography>
                      {renderContentWithSingleBulletPoint(
                        section.sectionContent
                      )}
                    </Box>
                  ))}
            </Grid>
          </Grid>
        </Container>
      </Section>

      {/* Appointment Section */}
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <AppointmentSectionStyle2
          bgUrl="/images/home_2/appointment_bg.svg"
          imgUrl="https://prohealth-html.vercel.app/assets/img/home_2/appointment_img.png"
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
        />
      </Section>

      {/* Banner Section */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          imgUrl="https://prohealth-html.vercel.app/assets/img/doctors/banner_img_3.png"
        />
      </Section>
    </>
  );
};

export default DoctorDetails;
