const initialState = {
  cities: [],
  states: [],
  hospitals: [],
  departments: [],
  designations: [],
  loading: true,
  error: null,
};

export default initialState;
