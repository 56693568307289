import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import DataProvider from "./components/DataContext";

// Admin imports
import Login from "./components/Login";
import HeaderAdmin from "./components/HeaderAdmin";

// Website imports

import Home from "./components/Pages/Home";
import About from "./components/Pages/About";
import Doctors from "./components/Pages/Doctors";
import DoctorDetails from "./components/Pages/DoctorDetails";
import Blog from "./components/Pages/Blog";
import Appointments from "./components/Pages/Appointments";
import Departments from "./components/Pages/Departments";
import DepartmentDetails from "./components/Pages/DepartmentDetails";
import BlogDetails from "./components/Pages/BlogDetails";
import PricingPlan from "./components/Pages/PricingPlan";
import Gallery from "./components/Pages/Gallery";
import Timetable from "./components/Pages/Timetable";
import Contact from "./components/Pages/Contact";
import ErrorPage from "./components/Pages/ErrorPage";

// Extra Layout and Styles (from the second project)
import Layout from "./components/Layout";
import Layout2 from "./components/Layout/Layout2";
import Layout3 from "./components/Layout/Layout3";
import Layout4 from "./components/Layout/Layout4";
import Layout5 from "./components/Layout/Layout5";
import HomeStyle2 from "./components/Pages/HomeStyle2";
import HomeStyle3 from "./components/Pages/HomeStyle3";
import HomeStyle4 from "./components/Pages/HomeStyle4";
import HomeStyle5 from "./components/Pages/HomeStyle5";
import HomeStyle6 from "./components/Pages/HomeStyle6";

const AdminPanel = lazy(() => import("./components/AdminPanel"));
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem("isAuthenticated", "true");
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    setIsAuthenticated(false);
  };

  return (
    <>
      <CssBaseline />
      <DataProvider>
        <Routes>
          {/* Login Route */}
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/admin" />
              ) : (
                <Login onLogin={handleLogin} />
              )
            }
          />

          {/* Admin Routes */}
          <Route
            path="/admin/*"
            element={
              isAuthenticated ? (
                <>
                  <HeaderAdmin onLogout={handleLogout} />{" "}
                  {/* Admin Panel Header */}
                  <div style={{ marginTop: "64px" }}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <AdminPanel />
                    </Suspense>
                  </div>
                </>
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* Additional Layouts for Home Variants */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="doctors" element={<Doctors />} />
            <Route path="doctor/:id" element={<DoctorDetails />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:blogId" element={<BlogDetails />} />
            <Route path="appointments" element={<Appointments />} />
            <Route path="departments" element={<Departments />} />
            <Route
              path="departments/:departmentId"
              element={<DepartmentDetails />}
            />
            <Route path="pricing-plan" element={<PricingPlan />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="timetable" element={<Timetable />} />
            <Route path="contact" element={<Contact />} />
          </Route>

          {/* Other Layout Variations */}
          <Route element={<Layout2 />}>
            <Route path="home-v2" element={<HomeStyle2 />} />
            <Route path="home-v6" element={<HomeStyle6 />} />
          </Route>
          <Route path="home-v3" element={<Layout3 />}>
            <Route index element={<HomeStyle3 />} />
          </Route>
          <Route path="home-v4" element={<Layout4 />}>
            <Route index element={<HomeStyle4 />} />
          </Route>
          <Route path="home-v5" element={<Layout5 />}>
            <Route index element={<HomeStyle5 />} />
          </Route>

          {/* Catch-all Route */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </DataProvider>
    </>
  );
};

export default App;
