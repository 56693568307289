import React from "react";
import HeroStyle6 from "../Hero/HeroStyle6";
import AboutSection from "../Section/AboutSection";
// import BrandsSection from "../Section/BrandsSection";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
import AwardSection from "../Section/AwardSection";
import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
import WorkingProcess from "../Section/WorkingProcess";

const faqData = [
  {
    title: "What is medical tourism?",
    content:
      "Medical tourism involves traveling to India for medical care. HelalHealthcare connects foreign customers with leading Indian healthcare providers.",
  },
  {
    title: "Which hospitals do you work with?",
    content:
      "We have partnerships with top hospitals across India, providing world-class medical services. Contact us to learn more about our network of hospitals.",
  },
  {
    title: "How do I book a medical tour?",
    content:
      "You can book a medical tour by filling out our online form or contacting our office. Our team will guide you through the process step by step.",
  },
  {
    title: "Do you offer post-treatment support?",
    content:
      "Yes, we provide follow-up care and support after treatment to ensure a smooth recovery, whether you stay in India or return to your home country.",
  },
  {
    title: "How do I know if my insurance covers treatment in India?",
    content:
      "We work with insurance providers to determine coverage options for treatments in India. Our team will help you understand your insurance benefits.",
  },
];

const workingProcessData = [
  {
    title: "Book Your Medical Tour",
    subTitle:
      "You can book a medical tour with us by <br />calling our office, filling out an online form, or <br />using our mobile app.",
    iconUrl: "https://img.icons8.com/papercut/100/event-accepted.png",
    number: "01",
  },
  {
    title: "Arrival and Accommodation",
    subTitle:
      "We will arrange for your accommodation and <br />transport upon your arrival in India. Our team will be there <br />to guide you through the process.",
    iconUrl: "https://img.icons8.com/fluency/100/airplane-landing.png",
    number: "02",
  },
  {
    title: "Meet Your Medical Team",
    subTitle:
      "Upon arrival, you will meet with our specialized <br />Indian doctors and healthcare professionals who <br />will guide you through your treatment plan.",
    iconUrl:
      "https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/100/external-Medical-Team-medical-concepts-smashingstocks-flat-smashing-stocks.png",
    number: "03",
  },
  {
    title: "Receive Treatment",
    subTitle:
      "We ensure that you receive world-class medical <br />care in India, tailored to your specific <br />healthcare needs.",
    iconUrl:
      "https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/100/external-Medical-Team-medical-concepts-smashingstocks-flat-smashing-stocks-2.png",
    number: "04",
  },
  {
    title: "Post-treatment and Follow-up",
    subTitle:
      "We coordinate your recovery and follow-up <br />care with healthcare professionals back in <br />your home country after your treatment in India.",
    iconUrl: "https://img.icons8.com/officel/100/treatment-plan.png",
    number: "05",
  },
];

const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Indian Healthcare Expertise",
    subTitle:
      "We connect foreign patients with top healthcare providers across India, ensuring world-class services and care.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Personalized Care",
    subTitle:
      "Our team tailors medical treatment plans to meet the individual needs of each patient, providing personalized attention at every step.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Affordable Healthcare",
    subTitle: `We work with Indian healthcare providers to ensure that patients receive affordable medical care without compromising on quality.`,
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Expert Guidance",
    subTitle:
      "We offer professional guidance through every stage of the medical tourism process, from initial consultation to follow-up care.",
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Trusted Indian Partners",
    subTitle:
      "Our partnerships with leading hospitals in India ensure that you receive the best treatment available.",
  },
];

const departmentData = [
  {
    title: "Orthopedics Department",
    iconUrl: "images/home_1/orthopedics.png",
    href: "/departments/department-details",
  },
  {
    title: "Pediatric Department",
    iconUrl: "images/home_1/pediatrics.png",
    href: "/departments/department-details",
  },
  {
    title: "Gynecology Department",
    iconUrl: "images/home_1/gynecology.png",
    href: "/departments/department-details",
  },
  {
    title: "Cardiology Department",
    iconUrl: "images/home_1/cardiology.png",
    href: "/departments/department-details",
  },
  {
    title: "Neurology Department",
    iconUrl: "images/home_1/neuron.png",
    href: "/departments/department-details",
  },
  {
    title: "Urology Department",
    iconUrl: "images/home_1/urology.png",
    href: "/departments/department-details",
  },
  {
    title: "Dentistry Department",
    iconUrl: "images/home_1/dentistry.png",
    href: "/departments/department-details",
  },
  {
    title: "Nephrology Department",
    iconUrl: "images/home_1/nephrology.png",
    href: "/departments/department-details",
  },
];

const awardData = [
  {
    title: "Malcolm Baldrige National Quality Award",
    subTitle:
      "This award recognizes healthcare organizations that have demonstrated excellence in leadership, strategic planning, customer and employee satisfaction, and operational efficiency.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "HIMSS Davies Award",
    subTitle:
      "This award recognizes healthcare organizations that have used health information technology to improve patient outcomes and reduce costs.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Healthgrades National’s Best Hospital",
    subTitle:
      "This recognition is given to Indian hospitals that have achieved high ratings for clinical quality and patient safety across multiple specialties and procedures.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Joint Commission Gold Seal of Approval",
    subTitle:
      "This recognition is given to Indian hospitals that have met rigorous standards for patient safety and quality of care.",
    iconUrl: "images/icons/award.svg",
  },
];

// const brandData = [
//   { imgUrl: "images/brand_1.png", imgAlt: "Brand" },
//   { imgUrl: "images/brand_2.png", imgAlt: "Brand" },
//   { imgUrl: "images/brand_3.png", imgAlt: "Brand" },
//   { imgUrl: "images/brand_4.png", imgAlt: "Brand" },
//   { imgUrl: "images/brand_5.png", imgAlt: "Brand" },
//   { imgUrl: "images/brand_6.png", imgAlt: "Brand" },
//   { imgUrl: "images/brand_7.png", imgAlt: "Brand" },
//   { imgUrl: "images/brand_8.png", imgAlt: "Brand" },
// ];

export default function Home() {
  pageTitle("Home - HelalHealthcare");
  return (
    <>
      <HeroStyle6
        title="Your Trusted Partner in Indian Medical Tourism"
        subTitle="HelalHealthcare connects foreign patients with world-class Indian healthcare providers for affordable and personalized care."
        bgUrl="https://prohealth-html.vercel.app/assets/img/home_2/hero_bg.jpeg"
        imgUrl="https://prohealth-html.vercel.app/assets/img/home_2/patents.png"
        funfactList={[
          { number: "50+", title: "Indian Healthcare Partners" },
          { number: "98%", title: "Client Satisfaction" },
          { number: "10000+", title: "Successful Treatments" },
          { number: "20+", title: "Countries Served" },
        ]}
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Why Choose Us?" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="https://prohealth-react.vercel.app/images/home_1/about.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About HelalHealthcare"
          subTitle="MEDICAL TOURISM EXPERTS"
          featureList={[
            {
              featureListTitle:
                "HelalHealthcare specializes in connecting foreign patients with top Indian healthcare providers.",
              featureListSubTitle:
                "We offer personalized medical tours, ensuring each patient receives the care and attention they deserve at affordable rates in India.",
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Specialized Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}

      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <WorkingProcess
          sectionTitle="How It Works"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={workingProcessData}
        />
      </Section>
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Recognitions & Awards" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="What Our Clients Say"
          sectionTitleDown="Testimonials"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="https://prohealth-react.vercel.app/images/home_1/cta_img.png"
          title="Start Your Medical Journey Today!"
          subTitle="Book an appointment with one of our healthcare experts and receive world-class care in India."
        />
      </Section>
      {/* End Banner Section */}

      {/* Start Appointment Section */}
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Book an Appointment"
          sectionTitleUp="MEDICAL CONSULTATION"
          imgUrl="https://prohealth-html.vercel.app/assets/img/home_1/appointment.jpeg"
        />
      </Section>
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Common Questions"
          sectionTitleUp="FREQUENTLY ASKED"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section> */}
      {/* End Brand Section */}
    </>
  );
}
