import React, { useEffect, useState } from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import Section from "../Section";
import { pageTitle } from "../../helpers/PageTitle";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  CircularProgress,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom"; // Fix for Link component error

const Doctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState(""); // Filter by hospital, department, or city
  const [filterValue, setFilterValue] = useState("");
  const doctorsPerPage = 16;

  useEffect(() => {
    const fetchDoctors = async () => {
      const querySnapshot = await getDocs(collection(db, "doctors"));
      setDoctors(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
      setLoading(false);
    };

    fetchDoctors();
  }, []);

  // Filter doctors based on selected filter type and value
  const filteredDoctors = doctors.filter((doctor) => {
    if (!filterType || !filterValue) return true; // Show all if no filter selected
    return doctor[filterType]?.toLowerCase() === filterValue.toLowerCase();
  });

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = filteredDoctors.slice(
    indexOfFirstDoctor,
    indexOfLastDoctor
  );

  // Setting the page title
  pageTitle("Doctors");

  if (loading) {
    return (
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="https://prohealth-html.vercel.app/assets/img/doctors/banner_img.png"
        title="Introduce You to <br />Our Experts"
        subTitle="The list of certified doctors with years of <br />professional experiences"
      />

      {/* Section with Padding and Title */}
      <Container sx={{ mt: 8, mb: 8 }}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          gutterBottom
          sx={{ mb: 5 }}
        >
          Doctors
        </Typography>

        {/* Filter By Options */}
        <Container
          sx={{ mb: 4, display: "flex", justifyContent: "space-between" }}
        >
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="filter-type-label">Filter By</InputLabel>
            <Select
              labelId="filter-type-label"
              value={filterType}
              label="Filter By"
              onChange={(e) => {
                setFilterType(e.target.value); // Set the filter type (hospital, department, city)
                setFilterValue(""); // Reset the selected filter value
              }}
            >
              <MenuItem value="hospital">Hospital</MenuItem>
              <MenuItem value="department">Department</MenuItem>
              <MenuItem value="city">City</MenuItem>
            </Select>
          </FormControl>

          {filterType && (
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="filter-value-label">{filterType}</InputLabel>
              <Select
                labelId="filter-value-label"
                value={filterValue}
                label={filterType}
                onChange={(e) => setFilterValue(e.target.value)}
              >
                {/* Dynamically generate unique filter values */}
                {[...new Set(doctors.map((doctor) => doctor[filterType]))]
                  .filter(Boolean)
                  .map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Container>

        {/* Doctors List */}
        <Grid container spacing={3}>
          {currentDoctors.map((doctor) => (
            <Grid item xs={12} sm={6} md={3} key={doctor.id}>
              <Link
                to={`/doctor/${doctor.id}`}
                style={{ textDecoration: "none" }}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%", // Ensure all cards have the same height
                  }}
                >
                  {/* Image section with a fixed height */}
                  <CardMedia
                    component="img"
                    height="250" // Set a fixed height for images
                    image={doctor.image || "/images/doctors/default.png"} // Provide a default image
                    alt={doctor.doctorName}
                    sx={{
                      objectFit: "contain", // Ensure image is contained within the box
                      backgroundColor: "#f5f5f5", // Optional: background color to fill gaps
                    }}
                  />
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      {doctor.doctorName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {doctor.department}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {doctor.hospital}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {doctor.city}, {doctor.state}, India
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={Math.ceil(filteredDoctors.length / doctorsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{ mt: 5, display: "flex", justifyContent: "center" }}
        />
      </Container>

      {/* Render Banner Section */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="https://prohealth-html.vercel.app/assets/img/doctors/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
        />
      </Section>
    </>
  );
};

export default Doctors;
